@tailwind base;
@tailwind components;
@tailwind utilities;


.bg-body-image {
  background-image: url("./bg.svg");
  background-repeat: no-repeat;
  background-size: cover;
}
